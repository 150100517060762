







































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import CommentBlock from './components/CommentBlock.vue'

@Component({
  components: {
    InfoBlock,
    CommentBlock
  },
})
export default class ProgramPasta extends Vue {
  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      'https://spics.wantu.cn/2ae16f91c602fcef16504ef281d5a7fa.png',
      'https://spics.wantu.cn/b499b0a63b10a1ca57f40f86900af190.png',
      'https://spics.wantu.cn/56c00a1aca14517b42894c76c6e0e0fa.png',
      'https://spics.wantu.cn/e338d50b056a99abf8c64fb37cc2a183.png',
      'https://spics.wantu.cn/6dddcf544d3c72bd997eacf5f875c42f.png',
    ]
  }
}
